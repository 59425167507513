import React from "react";
import * as styles from "./article-listing-term.module.scss";
import ArticleImageTitleTeaserList
    from "../../components/cards/article-image-title-teaser-list/article-image-title-teaser-list";
import BlockSectionDescription from "../../components/blocks/block-section-description/block-section-description";
import { graphql } from "gatsby";
import LinkPagination from "../../components/list-pagination/list-pagination";
import ArticleHeader from "../../components/blocks/article-header/article-header";
import Metatags from "../../components/metatags/metatags";
import WideColumnSidebar from "../wide-column-sidebar/wide-column-sidebar";
import {getImage} from "gatsby-plugin-image";
import slugify from "slugify";
import FooterTaxonomy from "../../components/blocks/footer/footer-taxonomy/footer-taxonomy";


export const query = graphql `
    query ($drupalTermId: Int!, $skip: Int!, $limit: Int!, $language: String!) {
      locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
      field_austrian_school: allNodeArticle(
        filter: {
          field_austrian_school: {elemMatch: {drupal_internal__target_id: {eq: $drupalTermId}}},
          langcode: {eq: $language}
        }
        limit: $limit
        skip: $skip
      ) {
        edges {
          node {
            ...articleDetails
          }
        }
      }
      field_tags: allNodeArticle(
        filter: {
          field_tags: {elemMatch: {drupal_internal__target_id: {eq: $drupalTermId}}},
          langcode: {eq: $language}
        }
        limit: $limit
        skip: $skip
      ) {
        edges {
          node {
            ...articleDetails
          }
        }
      }
      field_topics: allNodeArticle(
        filter: {
          field_topics: {elemMatch: {drupal_internal__target_id: {eq: $drupalTermId}}},
          langcode: {eq: $language}
        }
        limit: $limit
        skip: $skip
      ) {
        edges {
          node {
            ...articleDetails
          }
        }
      }
   }

    fragment articleDetails on node__article {
      title
      field_teaser {
        value
      }
      field_pub_date_mises_org(difference: "hours", fromNow: true)
      path {
        alias
      }
      relationships {
        field_images {
          relationships {
            field_media_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 320
                    height: 220
                    transformOptions: {cropFocus: CENTER, fit: COVER}
                  )
                }
              }
            }
          }
        }
        field_article_type {
          name
        }
        field_author {
          title
          id
        }
        field_topics {
          name
          id
          drupal_internal__tid
        }
        field_tags {
          name
          id
          drupal_internal__tid
        }
        field_austrian_school {
          drupal_internal__tid
          id
          name
        }
      }
      id
      langcode
    }
`;

export default function ArticleListingTerm({ data, props, pageContext }) {

    let wideContent = '';
    let title = '';
    const sidebar = "sidebar"
    let baseUrl = ''

    const taxonomies = [
        'field_austrian_school',
        'field_tags',
        'field_topics'
    ];
    taxonomies.forEach(function(taxonomyName) {
        // Show articles the term we loaded.
        const termArticles = data[taxonomyName].edges

        if (termArticles.length >= 1 &&
            termArticles[0].node.relationships[taxonomyName] !== undefined &&
            termArticles[0].node.relationships[taxonomyName][0] !== undefined &&
            termArticles[0].node.relationships[taxonomyName][0].name !== undefined) {

            const titleProps = {
                "key": 'h1',
                'className': styles.title
            };

            const termName = termArticles[0].node.relationships[taxonomyName][0].name;
            title = <ArticleHeader iconType={1} sectionName={termName} showNewsletter={false} isHeader={true}></ArticleHeader>


            baseUrl = slugify(termName, {lower: true, strict: true});
            console.log('baseUrl', baseUrl)
            // Add articles if any.
            wideContent = termArticles.map((article, index) => {
                const image = getImage(article.node.relationships.field_images[0].relationships.field_media_image.localFile.childImageSharp.gatsbyImageData)
                return <ArticleImageTitleTeaserList
                    key={index}
                    title={article.node.title}
                    teaser={article.node.field_teaser.value}
                    articleUrl={article.node.path.alias}
                    authorName={article.node.relationships.field_author[0].title}
                    authorUrl={article.node.articleUrl}
                    imageObj={image}
                    date={article.node.field_pub_date_mises_org}
                    articleType={article.node.relationships.field_article_type.name}>
                </ArticleImageTitleTeaserList>

            })
        }
    })


    const { currentPage, numPages } = pageContext

    const pagination = <LinkPagination baseUrl={baseUrl} currentPage={currentPage} numPages={numPages} ></LinkPagination>

    const content = [title, wideContent, pagination];

    const bottom = <FooterTaxonomy></FooterTaxonomy>

    return (
        <WideColumnSidebar wide={content} sidebar={sidebar} bottom={bottom}></WideColumnSidebar>
    )
}
