import React from 'react'
import {Link} from 'gatsby-plugin-react-i18next';

export default function LinkPagination({currentPage, numPages, baseUrl}) {

    let content = [];
    const lastPage = numPages - 1
    const isFirst = currentPage === 0
    const isLast = currentPage === lastPage
    const prevPageNumber = (currentPage - 1).toString();
    const prevPage = currentPage - 1 === 0 ? `/${baseUrl}` : `/${baseUrl}/${prevPageNumber}`
    const nextPage = `/${baseUrl}/` + (currentPage + 1).toString()

    if (!isFirst) {
        const prevLink = <Link to={prevPage} rel="prev">← Previous Page</Link>;
        content.push(prevLink)
    }

    if (!isLast) {
        const nextLink = <Link to={nextPage} rel="next">Next Page →</Link>;
        content.push(nextLink)
    }

    return(content);
}